define("discourse/plugins/discourse-follow/discourse/components/follow-statistic", ["exports", "@ember/component", "@ember/runloop", "discourse-common/utils/decorators"], function (_exports, _component, _runloop, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    classNames: "follow-statistic",
    init() {
      this._super();
      this.set("tagName", this.get("isCard") ? "h3" : "div");
    },
    didInsertElement() {
      this._super(...arguments);
      (0, _runloop.schedule)("afterRender", () => {
        let parent = this.get("isCard") ? ".card-content .metadata" : ".user-main .secondary dl";
        const parentElement = document.querySelector(parent);
        parentElement.prepend(this.element);
      });
    },
    isCard(context) {
      return context === "card";
    }
  }, [["method", "isCard", [(0, _decorators.default)("context")]]]));
});